import moment from 'moment';

import { theme } from '../theme/theme';
import { useEffect, useState } from 'react';

/**
 * used to retrive integer value from the string
 * @param dataString : target string value
 * @returns val: integervalue
 */
export const getIntVal = (dataString?: string) => {
  const val = dataString && dataString.replace(/[^0-9]/g, '');

  return val;
};

/**
 * used to check whether input is a valid JSON Object
 * @param str : target value
 * @returns a boolean
 */
export const isJSONObject = (str?: any): boolean => {
  if (str && typeof str === 'string' && str.startsWith('{') && str.endsWith('}')) {
    return true;
  }
  return false;
};

/**
 * used to convert a Date to a formatted string
 * @param date : target Date
 * @param format : target format
 * @returns a boolean
 */
export const getFormattedDate = (date: Date, format = 'DD MMM, YYYY'): string => {
  if (date) {
    return moment(date).format(format);
  }

  return 'N/A';
};

/**
 * used to retrive integer value in number type from the string
 * @param dataString : target string value
 * @returns val: number (default is 0)
 */
export const convertToInt = (dataString?: string | null): number => {
  try {
    if (dataString) {
      return Number(dataString.replace(/[^0-9]/g, ''));
    }
  } catch (error) {
    return 0;
  }

  return 0;
};

/**
 * used to handle the font variaent depend on the prices
 * @params dataString : target string value
 * @return font varient name, and class
 */

export const getAmountFontVarient = (dataString: string) => {
  let variant = 'h5Bold';
  let classes = 'h5_view';

  const getIntValue: any = getIntVal(dataString);
  const convertintoIntValue = parseInt(getIntValue);

  if (convertintoIntValue > 100000) {
    variant = 'h4Bold';
    classes = 'h4_view';
  }

  if (convertintoIntValue > 10000000) {
    variant = 'h3Bold';
    classes = 'h3_view';
  }

  return [variant, classes];
};

/**
 * used to remove space from the string
 * @param dataString : target string value
 * @returns val: string
 */
export const getSpaceRemovedString = (dataString?: string) => {
  const val = dataString ? dataString.replace(/\s/g, '') : null;

  return val;
};

/**
 * Get First Letters from First and Last name
 * @param primaryContactName
 * @returns
 */
export const getNameWords = (primaryContactName: string) => {
  const splitFullName = primaryContactName.split(' ');
  const firstName = splitFullName[0] ? splitFullName[0][0] : null;
  const lastName = splitFullName.length > 1 ? splitFullName[splitFullName.length - 1][0] : null;
  const joinWords = `${firstName ?? ''}${lastName ?? ''}`;

  return joinWords;
};

export const handleUserIconColor = (index: any) => {
  let color = theme.palette.darkBlue[50];
  if (index === 0) {
    color = theme.palette.blue[100];
  } else if (index === 1) {
    color = theme.palette.darkBlue[50];
  } else if (index === 2) {
    color = theme.palette.gray[100];
  } else if (index === 3) {
    color = theme.palette.yellow[50];
  } else if (index === 4) {
    color = theme.palette.green[50];
  } else if (index === 5) {
    color = theme.palette.gray[50];
  } else if (index === 6) {
    color = theme.palette.darkBlue[100];
  } else if (index === 7) {
    color = theme.palette.gray[100];
  } else if (index === 8) {
    color = theme.palette.blue[50];
  } else if (index === 9) {
    color = theme.palette.green[100];
  } else {
    color = theme.palette.darkBlue[50];
  }
  return color;
};

/**
 * Get firstname and last name from the full name
 */

export const splitFullName = (getFullNameValue: string = '') => {
  const splitFullName = getFullNameValue.split(' ');
  const firstName = splitFullName[0] ? splitFullName[0] : null;
  const lastName = splitFullName.length > 1 ? splitFullName[splitFullName.length - 1] : null;

  return [firstName, lastName];
};

/**
 * Handle Seetting -> Account info verification status and messages
 */
export const handleSettingBusinessAIVSAM = (result: any, t: any) => {
  const initialMessageObject = {
    heading: '',
    content: '',
    buttonText: t('errorMessages.reviewError.linkText'),
    triggerType: 'personal',
  };

  const [verifyObj, setVerifyObj] = useState<any>({
    business: '',
    personal: '',
    tos: false,
  });

  const [messageObject, setMessageObject] = useState<any>(initialMessageObject);

  useEffect(() => {
    const getIndividualObj = result?.individual;
    const getCompanylObj = result?.company;
    const getCompanyStructure = result?.company_structure;
    const isTOSaccepted = result?.tos_shown_and_accepted;
    const getCurrentDueArray = result?.currently_due_requirements;
    const getCompanyAddressObj = getCompanylObj?.address;
    const getCompanyVerificationObj = getCompanylObj?.verification;
    const getIndividualVerificationObj = getIndividualObj?.verification;
    const getDobObj = getIndividualObj?.dob;

    // get relationship related details ___
    const getRelationshipObj = getIndividualObj?.relationShip;
    const isSoleOwnerCompanyStructure = getCompanyStructure === 'sole_proprietorship';
    const IsOwnerTypeNotSelected =
      !getRelationshipObj?.executive &&
      !getRelationshipObj?.owner &&
      !getRelationshipObj?.representative &&
      !getRelationshipObj?.director;

    // get personal details ___
    const isFirstNameProvided = getIndividualObj?.first_name ? true : false;
    const isLastNameProvided = getIndividualObj?.last_name ? true : false;
    const isEmailProvided = getIndividualObj?.email ? true : false;
    const isPersonalPhoneProvided = getIndividualObj?.phone ? true : false;
    const isDOBNotProvided = !getDobObj?.day || !getDobObj?.month || !getDobObj?.year;

    // get company details ___
    const isComanyNameProvided = getCompanylObj?.name ? true : false;
    const isCompanyPhoneProvided = getCompanylObj?.phone ? true : false;
    const isCompanyIDProvided =
      getCompanylObj?.tax_id_provided && !getCurrentDueArray.includes('company.tax_id');
    const isCompanyAddressNotProvided =
      !getCompanyAddressObj?.city ||
      !getCompanyAddressObj?.line1 ||
      !getCompanyAddressObj?.postal_code ||
      !getCompanyAddressObj?.state;

    // get company verification document
    const taxDocFront = getCompanyVerificationObj?.document?.front;
    const taxDocBack = getCompanyVerificationObj?.document?.back;
    const isTaxDocIDAdded =
      (taxDocFront && taxDocFront !== undefined) || (taxDocBack && taxDocBack !== undefined)
        ? true
        : false;
    const isTaxDocumentVerified = isTaxDocIDAdded && !getCurrentDueArray.includes('company.tax_id');

    // handle personal verification flow
    const getVerificationStatus = result?.individual?.verification?.status;
    const isSSNLastProvided = getIndividualObj?.ssn_last4_provided ? true : false;
    const verificationDocumentFront = getIndividualVerificationObj?.document?.front;
    const isVerificationDocumentProvided = verificationDocumentFront ? true : false;

    const isBusinessInfoScreenVerified =
      isComanyNameProvided &&
      isCompanyPhoneProvided &&
      (isCompanyIDProvided || isTaxDocumentVerified);

    const isBusinessAddressVerified = !isCompanyAddressNotProvided;

    const isBusinessDetailsAdded = isBusinessInfoScreenVerified && isBusinessAddressVerified;
    const isCommonPerosnalDetailsAdded =
      isFirstNameProvided && isLastNameProvided && isEmailProvided && isPersonalPhoneProvided;

    const isExecutiveIDVerifyScreenDetailsVerified =
      (isSSNLastProvided || isVerificationDocumentProvided) &&
      !isDOBNotProvided &&
      getVerificationStatus === 'verified';
    const isExecutiveIDVerifyScreenDetailsVPending =
      (isSSNLastProvided || isVerificationDocumentProvided) &&
      !isDOBNotProvided &&
      getVerificationStatus === 'pending';
    const isExecutiveIDVerifyScreenDetailsUnverified =
      (!isSSNLastProvided || !isVerificationDocumentProvided) &&
      getVerificationStatus === 'unverified';

    const handleMessageContent = (
      messageInfoType: string = '',
      isExucutiveUnverified: boolean = false
    ) => {
      return `Please review your ${messageInfoType} info such as ${
        !isFirstNameProvided ? 'first name,' : ''
      } ${!isLastNameProvided ? 'last name,' : ''} ${
        !isComanyNameProvided ? 'comapny name,' : ''
      } ${isCompanyAddressNotProvided ? 'address,' : ''} ${
        !isPersonalPhoneProvided || !isCompanyPhoneProvided ? 'phone,' : ''
      } ${!isEmailProvided ? 'email,' : ''} ${
        IsOwnerTypeNotSelected && !isExucutiveUnverified ? 'ownership details.' : ''
      } ${
        isExucutiveUnverified && isDOBNotProvided
          ? `DOB${!isExecutiveIDVerifyScreenDetailsUnverified ? '.' : ','}`
          : ''
      } ${
        isExucutiveUnverified && isExecutiveIDVerifyScreenDetailsUnverified ? 'SSN or ID Doc.' : ''
      }`;
    };

    const onlyExecutiveUnverifiedMessageContent = `Please review your personal info such as DOB or SSN`;

    const tosAcceptanceFailedMessage = `This account must accept the terms of service`;
    const onlyCompanyAddressUnAvailable = `Please update your company address`;

    const handleCommonConditionFlow = (personalStatus: string = 'verified') => {
      if (isBusinessDetailsAdded && !isTOSaccepted) {
        setVerifyObj({
          business: 'verified',
          personal: personalStatus,
        });

        setMessageObject({
          buttonText: 'Accept',
          triggerType: 'tos',
          heading: 'Account Restricted',
          content: tosAcceptanceFailedMessage,
        });
      } else if (isBusinessInfoScreenVerified && !isBusinessAddressVerified) {
        setVerifyObj({
          business: 'unverified',
          personal: personalStatus,
        });

        setMessageObject({
          ...initialMessageObject,
          triggerType: 'business',
          heading: 'Business address is incomplete',
          content: onlyCompanyAddressUnAvailable,
        });
      } else if (!isCompanyIDProvided && isBusinessAddressVerified) {
        setVerifyObj({
          business: 'unverified',
          personal: personalStatus,
        });

        setMessageObject({
          ...initialMessageObject,
          triggerType: 'business',
          heading: 'EIN Verification Failed',
          content: 'Please provide the valid EIN',
        });
      } else if (!isBusinessInfoScreenVerified && !isBusinessAddressVerified) {
        setVerifyObj({
          business: 'unverified',
          personal: personalStatus,
        });

        setMessageObject({
          ...initialMessageObject,
          triggerType: 'business',
          heading: 'Business details are incomplete',
          content: handleMessageContent('business'),
        });
      } else if (isBusinessInfoScreenVerified && isBusinessAddressVerified) {
        setVerifyObj({
          business: 'verified',
          personal: personalStatus,
        });

        setMessageObject({
          heading: '',
          content: '',
        });
      }
    };

    if (IsOwnerTypeNotSelected) {
      if (isBusinessDetailsAdded && !isCommonPerosnalDetailsAdded) {
        setVerifyObj({
          business: 'verified',
          personal: 'unverified',
        });

        setMessageObject({
          ...initialMessageObject,
          heading: 'Personal details are incomplete',
          content: handleMessageContent('personal'),
        });
      } else {
        setVerifyObj({
          business: 'unverified',
          personal: 'unverified',
        });

        setMessageObject({
          ...initialMessageObject,
          triggerType: 'hide',
          heading: t('errorMessages.reviewError.heading'),
          content: handleMessageContent(''),
        });
      }
    } else {
      if (isCommonPerosnalDetailsAdded) {
        if (isSoleOwnerCompanyStructure === true) {
          if (isExecutiveIDVerifyScreenDetailsVerified) {
            handleCommonConditionFlow('verified');
          } else if (isExecutiveIDVerifyScreenDetailsVPending) {
            handleCommonConditionFlow('pending');
          } else if (isExecutiveIDVerifyScreenDetailsUnverified) {
            if (isBusinessDetailsAdded && !isDOBNotProvided) {
              setVerifyObj({
                business: 'verified',
                personal: 'unverified',
              });

              setMessageObject({
                ...initialMessageObject,
                heading: 'Verification SSN or ID incomplete',
                content:
                  'Please provide the valid SSN, don’t have a SSN? upload your identity document.',
              });
            } else if (isBusinessDetailsAdded && isDOBNotProvided) {
              setVerifyObj({
                business: 'verified',
                personal: 'unverified',
              });

              setMessageObject({
                ...initialMessageObject,
                heading: 'Personal details are incomplete',
                content: onlyExecutiveUnverifiedMessageContent,
              });
            } else if (!isCompanyIDProvided && isBusinessAddressVerified) {
              setVerifyObj({
                business: 'unverified',
                personal: 'unverified',
              });

              setMessageObject({
                ...initialMessageObject,
                heading: t('errorMessages.reviewError.heading'),
                content: handleMessageContent('', true),
              });
            } else if (!isBusinessInfoScreenVerified && !isBusinessAddressVerified) {
              setVerifyObj({
                business: 'unverified',
                personal: 'unverified',
              });

              setMessageObject({
                ...initialMessageObject,
                heading: t('errorMessages.reviewError.heading'),
                content: handleMessageContent('', true),
              });
            }
          }
        } else {
          handleCommonConditionFlow('verified');
        }
      }
    }
  }, [result]);

  return [messageObject, verifyObj];
};

export const handlePayModeDisplay = (paymodeValue: string = '', isDisplaySimpleCase?: boolean) => {
  let _paymode = paymodeValue.toLocaleUpperCase();

  if (
    paymodeValue === 'debit_card' ||
    paymodeValue === 'DEBIT_CARD' ||
    paymodeValue === 'Debit_Card'
  ) {
    _paymode = isDisplaySimpleCase ? 'debit_card' : 'Instant';
  }

  if (paymodeValue === 'check' || paymodeValue === 'CHECK') {
    _paymode = isDisplaySimpleCase ? 'check' : 'Check';
  }

  if (paymodeValue === 'ach' || paymodeValue === 'ACH' || paymodeValue === 'Ach') {
    _paymode = isDisplaySimpleCase ? 'ach' : 'ACH';
  }
  if (paymodeValue.toLowerCase() === 'digital_check') {
    _paymode = isDisplaySimpleCase ? 'digital check' : 'Digital Check';
  }

  return _paymode;
};

/**
 * Check is batch paymode is instant or not
 * @param pay_mode send the paymode
 * @returns boolean value true or false
 */
export const checkIsInstandPaymode = (pay_mode: string) => {
  const isInstantPayMode =
    pay_mode === 'DEBIT_CARD' || pay_mode === 'Debit_Card' || pay_mode === 'debit_card'
      ? true
      : false;

  return isInstantPayMode;
};

export const checkIsInstantPayModeAndTreasuryEnabled = (data: any) => {
  let isInstantPayModeAndTreasuryEnabled = false;

  if (
    data &&
    data?.default_pay_mode === 'debit_card' &&
    data?.treasury_status === 'active' &&
    data?.treasury_account_id !== null
  ) {
    isInstantPayModeAndTreasuryEnabled = true;
  }

  return [isInstantPayModeAndTreasuryEnabled];
};

export const getApprovalsStatusSorting = (
  payoutStatus?: string,
  paymode?: string,
  checkStatus?: string
) => {
  let buttonText: any = payoutStatus;

  if (paymode === 'CHECK' || paymode === 'check') {
    buttonText = checkStatus;

    if (checkStatus === 'UNPAID') {
      buttonText = 'Unpaid';
    } else if (checkStatus === 'IN_PROCESS') {
      buttonText = 'Check Issued';
    } else if (checkStatus === 'VOID') {
      buttonText = 'Check Voided';
    } else if (checkStatus === 'EXPIRED') {
      buttonText = 'Check Expired';
    } else if (checkStatus === 'PRINTED') {
      buttonText = 'Check Printed';
    } else if (checkStatus === 'MAILED') {
      buttonText = 'Check In Transit';
    } else if (checkStatus === 'FAILED') {
      buttonText = 'Check Failed';
    } else if (checkStatus === 'REFUNDED') {
      buttonText = 'Check Refunded';
    } else if (checkStatus === 'CHECK_PAID') {
      buttonText = 'Check Cashed';
    }
  } else {
    if (payoutStatus === 'APPROVED') {
      buttonText = 'Approved';
    } else if (payoutStatus === 'PAID') {
      buttonText = 'Paid';
    } else if (payoutStatus === 'INVOICED') {
      buttonText = 'Submitted';
    } else if (payoutStatus === 'DENIED') {
      buttonText = 'Void';
    } else if (payoutStatus === 'REVERSED') {
      buttonText = 'Reversed';
    } else if (payoutStatus === 'TERMS_ACCEPTED') {
      buttonText = null;
    }
  }

  return buttonText;
};

/**
 * Get the approval and expected dated based n the PayMode
 */
export const getEstDatesForPayMode = (
  _payMode?: string,
  estimatedPayoutDate?: string,
  activePayMode?: string,
  paymentArrivalEstimatedDate?: string
) => {
  let dates = {
    submittedDate: '',
    arrivalDate: '',
  };

  const isActivePaymodeCheck = activePayMode === 'check' || activePayMode === 'CHECK';
  const isActivePaymodeAch = activePayMode === 'ach' || activePayMode === 'ACH';
  const isActivePaymodeInstant =
    activePayMode === 'debit_card' ||
    activePayMode === 'DEBIT_CARD' ||
    activePayMode === 'Debit_Card';

  if (estimatedPayoutDate && estimatedPayoutDate !== '') {
    if (_payMode === 'debit_card' || _payMode === 'DEBIT_CARD' || _payMode === 'Debit_Card') {
      dates = {
        submittedDate: moment(estimatedPayoutDate).format('MMM DD'),
        arrivalDate: isActivePaymodeInstant
          ? moment(paymentArrivalEstimatedDate).format('MMM DD')
          : moment(estimatedPayoutDate).format('MMM DD'),
      };
    }

    if (_payMode === 'check' || _payMode === 'CHECK') {
      dates = {
        submittedDate: '',
        arrivalDate: isActivePaymodeCheck
          ? moment(paymentArrivalEstimatedDate).format('MMM DD')
          : moment(estimatedPayoutDate).add(30, 'days').format('MMM DD'),
      };
    }

    if (_payMode === 'ach' || _payMode === 'ACH' || _payMode === 'Ach') {
      dates = {
        submittedDate: moment(estimatedPayoutDate).format('MMM DD'),
        arrivalDate: isActivePaymodeAch
          ? moment(paymentArrivalEstimatedDate).format('MMM DD')
          : moment(estimatedPayoutDate).add(4, 'days').format('MMM DD'),
      };
    }
  }

  return dates;
};
